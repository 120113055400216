import React, { useEffect, useState } from "react";
import { ReactComponent as CaretDown } from "../../../assets/icons/CaretDown.svg";
import dayjs from "dayjs";
import PopperDropdown from "../../../components/DropDowns/PopperDropdown";
import Button from "../../../components/sub-components/Button";
import { useDispatch, useSelector } from "react-redux";
import BackgroundBlur from "../../../components/sub-components/Blur";
import SurveyAnalytics from "./SurveyAnalytics";
import SurveyAnalyticsOverview from "./SurveyAnalyticsOverview";
import { mySurveyDummy } from "../../../utilities/dummyGraphs/surveyDummy";
import { DateRangePicker } from "../../../components/DatePickers/DateRangePicker";
import SurveyResponseRate from "./SurveyResponseRate";
import {
  emptySingleSurvey,
  listAllSurveys,
  listSingleSurvey,
  toggleSideMenu,
} from "../../../redux/slices/surveySlice";
import CreateNewSurveyMenu from "./SurveysMenu";
import SurveySideMenu from "./SurveysMenu/SurveySideMenu";
import {
  getGradeMapping,
  getSurveyGradeBackgroundColors,
  getSurveyGradeTextColors,
} from "../../../utilities/Utils";
import SurveyQuestions from "./SurveysMenu/SurveyQuestions";

const Surveys = () => {
  const dispatch = useDispatch();

  const [teamsData, setTeamsData] = useState([]); // users to show inside dropdown
  const [cohortsData, setCohortsData] = useState([]); // users to show inside dropdown
  const [usersData, setUsersData] = useState([]); // users to show inside dropdown
  const [organizationItemSelected, setOrganizationItemSelected] = useState({});
  const [organizationItemApplied, setOrganizationItemApplied] = useState({});
  const [organizationData, setOrganizationData] = useState([]);
  const [isTeamSelected, setIsTeamSelected] = useState(false);
  const [currentSurveyOpened, setCurrentSurveyOpened] = useState(null);
  const [isCurrentSurveyOpened, setIsCurrentSurveyOpened] = useState(false);
  const [isCurrentViewMenuOpen, setIsCurrentViewMenuOpen] =
    React.useState(false);
  const currentViewAnchorEl = React.useRef(null);

  const [startDate, setStartDate] = useState(
    dayjs().subtract(1, "week").format("YYYY-MM-DDTHH:mm:ss.SSS"),
  );
  const [endDate, setEndDate] = useState(
    dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
  );
  const [isDateMenuOpen, setIsDateMenuOpen] = useState(false);
  const [currentrow, setCurrentrow] = useState({});

  const [isNestedFilterationMenuOpen, setIsNestedFilterationMenuOpen] =
    React.useState(false);

  const { isSideMenuOpen } = useSelector(
    (state) => state.surveysReducer.sideMenu,
  );
  const { mySurveysData, mySurveyCurrentlyOpened } = useSelector(
    (state) => state.surveysReducer,
  );

  const handleNestedFilterationMenuClose = () => {
    setIsNestedFilterationMenuOpen(false);
  };

  const handleClickCurrentViewOption = (item) => {
    if (item === organizationItemSelected) {
      setOrganizationItemSelected({});
    } else {
      setOrganizationItemSelected(item);
    }
  };

  const handleCancelOrganizationFilter = () => {
    handleNestedFilterationMenuClose();
    setOrganizationItemSelected(organizationItemApplied);
  };

  const handleSideMenu = () => {
    dispatch(toggleSideMenu());
  };

  const handleGenericMenuOpen = (setIsGenericMenuOpen) => {
    setIsGenericMenuOpen((prevOpen) => !prevOpen);
  };
  const handleGenericMenuClose = (setIsGenericMenuOpen) => {
    setIsGenericMenuOpen(false);
  };

  useEffect(() => {
    if (isTeamSelected) {
      setOrganizationData([...teamsData, ...cohortsData]);
    } else {
      setOrganizationData([...teamsData, ...usersData, ...cohortsData]);
    }
  }, [isTeamSelected]);
  useEffect(() => {
    const isTeamItemSelected = Object.prototype.hasOwnProperty.call(
      organizationItemApplied,
      "group_id",
    );
    if (isTeamItemSelected) {
      setIsTeamSelected(true);
    } else {
      setIsTeamSelected(false);
    }
  }, [organizationItemApplied]);

  useEffect(() => {
    dispatch(listAllSurveys());
  }, []);

  const handleApplyOrganizationFilter = () => {
    setOrganizationItemApplied(organizationItemSelected);
    handleNestedFilterationMenuClose();
  };

  const fetchDatesData = async (selectedStartDate, selectedEndDate) => {
    setStartDate(selectedStartDate);
    setEndDate(selectedEndDate);
  };

  const handleCreateNewObjective = () => {
    setCurrentrow({});
    dispatch(toggleSideMenu());
  };

  // overview click
  const handleSurveyClick = (surveyData) => {
    setIsCurrentSurveyOpened(false);
    setCurrentrow(mySurveyDummy[0].surveys[0]);
    dispatch(emptySingleSurvey());
    dispatch(toggleSideMenu());
    setCurrentSurveyOpened(mySurveyDummy[0].surveys[0]);
  };

  // survey questions
  const handleCurrentSurvey = (surveyQuestions) => {
    console.log("surveyQuestions", surveyQuestions);
    // dispatch(listSingleSurvey(surveyQuestions.id));
    dispatch(listSingleSurvey(surveyQuestions.id))
      .unwrap()
      .then((payload) => dispatch(toggleSideMenu()));
    // .catch((error) => console.error("rejected", error));
    // setIsCurrentSurveyOpened(true);
    // setCurrentSurveyOpened(surveyQuestions);

    // setCurrentrow(surveyQuestions);
  };

  return (
    <div
      className="page-body-new"
      style={{ display: "block", padding: "0 24px 44px 27px" }}
    >
      <div className="row">
        <div className="col-12">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <PopperDropdown
                anchorRef={currentViewAnchorEl}
                isDropdownOpen={isCurrentViewMenuOpen}
                handleToggle={() =>
                  handleGenericMenuOpen(setIsCurrentViewMenuOpen)
                }
                handleClose={() =>
                  handleGenericMenuClose(setIsCurrentViewMenuOpen)
                }
                dropdownDisplayText="Users"
                customRenderFieldChildren={
                  <div
                    className="d-flex align-items-center head4-semi-bold"
                    style={{ cursor: "pointer" }}
                  >
                    All Surveys{" "}
                    <CaretDown
                      stroke="black"
                      stroke-width="3"
                      className="ml-2"
                    />
                  </div>
                }
                dropdownWithSearchAndCheckbox
                dropdownData={organizationData}
                handleClickCheckbox={handleClickCurrentViewOption}
                handleApply={handleApplyOrganizationFilter}
                handleCancel={handleCancelOrganizationFilter}
                dropdownItemsSelected={organizationItemSelected}
                keyToAccess="name"
              />
            </div>

            <div className="d-flex align-items-center">
              <div className="mr-3">
                <DateRangePicker
                  value={{ from: startDate, to: endDate }}
                  fetchDatesData={fetchDatesData}
                  setIsDateMenuOpen={setIsDateMenuOpen}
                  isDateMenuOpen={isDateMenuOpen}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                />
              </div>

              <Button
                buttonAddtoTeam
                title="Survey"
                onClick={() => handleCreateNewObjective()}
                style={{
                  boxShadow: "none",
                  background: "#9300FF",
                  padding: "0 36px",
                  // width: "236px",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-4">
          <SurveyAnalyticsOverview
            graphName={"Performance Surveys"}
            usersData={usersData}
            indexBy={"user_id"}
            graphType="performance"
            productivityGraph
            organizationItemSelected={organizationItemSelected}
            handleSurveyClick={handleSurveyClick}
          />
        </div>
        <div className="col-lg-4">
          <SurveyAnalyticsOverview
            graph={"Well Being Surveys"}
            graphName={"Well Being Surveys"}
            teamsData={teamsData}
            usersData={usersData}
            cohortsData={cohortsData}
            indexBy={"user_id"}
            graphType="well being"
            productivityGraph
            isTeamSelected={isTeamSelected}
            userProfilesExtracted={
              organizationItemApplied?.team_group &&
              organizationItemApplied?.team_group[0]?.group_members.map(
                (group) => group.user_profile,
              )
            }
            organizationItemSelected={organizationItemSelected}
            handleSurveyClick={handleSurveyClick}
          />
        </div>
        <div className="col-lg-4">
          <SurveyAnalyticsOverview
            graph={"Happiness Surveys"}
            graphName={"Happiness Surveys"}
            teamsData={teamsData}
            usersData={usersData}
            cohortsData={cohortsData}
            indexBy={"user_id"}
            graphType="personal"
            productivityGraph
            isTeamSelected={isTeamSelected}
            enableGridX={true}
            userProfilesExtracted={
              organizationItemApplied?.team_group &&
              organizationItemApplied?.team_group[0]?.group_members.map(
                (group) => group.user_profile,
              )
            }
            organizationItemSelected={organizationItemSelected}
            handleSurveyClick={handleSurveyClick}
          />
        </div>
      </div>

      <div className="d-flex flex-column">
        <SurveyResponseRate
          graph={"Survey Response Rate"}
          graphName={"Survey Response Rate"}
          teamsData={teamsData}
          usersData={usersData}
          cohortsData={cohortsData}
          indexBy={"user_id"}
          graphType="productivityGraph"
          productivityGraph
          isTeamSelected={isTeamSelected}
          userProfilesExtracted={
            organizationItemApplied?.team_group &&
            organizationItemApplied?.team_group[0]?.group_members.map(
              (group) => group.user_profile,
            )
          }
          organizationItemSelected={organizationItemSelected}
        />
      </div>

      <div className="d-flex flex-column">
        <SurveyAnalytics
          graph={"Total Surveys "}
          graphName={"Total Surveys "}
          teamsData={teamsData}
          usersData={usersData}
          cohortsData={cohortsData}
          indexBy={"user_id"}
          graphType="productivityGraph"
          productivityGraph
          isTeamSelected={isTeamSelected}
          userProfilesExtracted={
            organizationItemApplied?.team_group &&
            organizationItemApplied?.team_group[0]?.group_members.map(
              (group) => group.user_profile,
            )
          }
          organizationItemSelected={organizationItemSelected}
        />
      </div>
      {/* -------------- NEED TO REFACTOR THIS CONDITIONS --------------*/}
      {mySurveyCurrentlyOpened.length > 0 ? (
        <div
          className={`side-menu-box ${
            isSideMenuOpen ? "open border" : "side-menu-box border"
          }`}
        >
          <SurveySideMenu
            isSideMenuOpen={isSideMenuOpen}
            currentrow={currentrow}
            setCurrentrow={setCurrentrow}
            currentSurveyOpened={currentSurveyOpened}
            isCurrentSurveyOpened={isCurrentSurveyOpened}
            mySurveyCurrentlyOpened={mySurveyCurrentlyOpened}
          />
        </div>
      ) : mySurveyCurrentlyOpened.length === 0 &&
        Object.keys(currentrow).length > 0 ? (
        <div
          className={`side-menu-box ${
            isSideMenuOpen ? "open border" : "side-menu-box border"
          }`}
        >
          <SurveySideMenu
            isSideMenuOpen={isSideMenuOpen}
            currentrow={currentrow}
            setCurrentrow={setCurrentrow}
            currentSurveyOpened={currentSurveyOpened}
            isCurrentSurveyOpened={isCurrentSurveyOpened}
          />
        </div>
      ) : (
        mySurveyCurrentlyOpened.length === 0 &&
        Object.keys(currentrow).length === 0 && (
          <CreateNewSurveyMenu
            isSideMenuOpen={isSideMenuOpen}
            currentrow={currentrow}
            handleSideMenu={handleSideMenu}
            setCurrentrow={setCurrentrow}
            currentSurveyOpened={currentSurveyOpened}
            currentRowToEdit={currentrow}
            setCurrentRowToEdit={setCurrentrow}
          />
        )
      )}
      {isSideMenuOpen && <BackgroundBlur closeMenu={handleSideMenu} />}

      <div className="row">
        <div className="col-lg-6">
          <div
            style={{
              marginTop: "30px",
              maxHeight: "444px",
              minHeight: "444px",
              overflowY: "scroll",
              boxShadow: "2px 2px 15px 0px #E5D5F2",
              borderRadius: "20px",
              border: "1px solid #C6C6C6",

              // display: "flex",
              // flexDirection: "column",
              // alignItems: "flex-start",
              padding: "15px 20px",
            }}
            // className="p-2"
            // className="row"
          >
            <div className="head6-medium mb-3">{"My Surveys"}</div>
            {mySurveysData?.map((survey, index) => {
              const surveyResponsePercetage = Math.round(
                (survey?.survey_responses.length /
                  survey?.survey_assignees.length) *
                  100,
              );
              return (
                <div
                  key={index}
                  className="col-lg-12 p-0"
                  style={{
                    marginBottom: "16px",
                    boxShadow: "0px 4px 4px 0px #FAFAFA",
                    // border: "3px solid #F5F5F5",
                    width: "100%",
                  }}
                >
                  <SurveyQuestions
                    currentrow={survey}
                    handleSurveyResponseClick={handleCurrentSurvey}
                  />
                  {/* <div
                    className="d-flex justify-content-between col-lg-12"
                    style={{
                      // border: "1px solid #DEDEDE",
                      border: "1px solid #DEDEDE",
                      borderRadius: "10px",
                      padding: "12px 11px",
                      // maxHeight: "90px",
                      margin: "12px 0",
                    }}
                  >
                    <div className="d-flex">
                      <div
                        className="d-flex flex-column align-items-center"
                        style={{
                          background: getSurveyGradeBackgroundColors(
                            getGradeMapping(
                              Math.round(surveyResponsePercetage),
                            ),
                          ), // Placeholder
                          marginRight: "26px",
                          borderRadius: "10px",
                          padding: "12px 21px",
                          width: "92px",
                          // padding: "10px 0",
                        }}
                      >
                        <span
                          className="d-flex head3-semi-bold mb-3"
                          style={{
                            color: getSurveyGradeTextColors(
                              getGradeMapping(
                                Math.round(surveyResponsePercetage),
                              ),
                            ), // Placeholder
                          }}
                        >
                          {getGradeMapping(Math.round(surveyResponsePercetage))}
                        </span>
                        <span
                          className="d-flex head6-medium"
                          style={{
                            color: getSurveyGradeTextColors(
                              getGradeMapping(
                                Math.round(surveyResponsePercetage),
                              ),
                            ), // Placeholder
                          }}
                        >
                          {Math.round(surveyResponsePercetage) || 0}
                          {"%"}
                        </span>
                      </div>
                      <div
                        className="d-flex justify-content-between flex-column"
                        style={{ padding: "14px 0" }}
                      >
                        <div className="d-flex head5-medium grey9 mb-3">
                          {survey?.question}
                        </div>
                        <span
                          className="d-flex primary-color caption1"
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => handleCurrentSurvey(survey)}
                        >
                          {"See Responses"}
                        </span>
                      </div>
                    </div>
                    <div
                      className="d-flex justify-content-between flex-column"
                      style={{ padding: "20px 0" }}
                    >
                      <div className="d-flex caption1 grey9">
                        {`Asked By: ${survey.user_profile ? `${survey.user_profile.first_name} ${survey.user_profile.last_name}` : ""}`}
                      </div>
                      <span className="d-flex caption1 grey9">{`Created at: ${survey.start_date ? dayjs(survey.start_date).format("DD/MM/YYYY") : ""}`}</span>
                    </div>
                  </div> */}
                </div>
              );
            })}
          </div>
        </div>

        <div className="col-lg-6">
          <SurveyAnalyticsOverview
            graph={"Are you satisfied with HR?"}
            graphName={"Are you satisfied with HR?"}
            teamsData={teamsData}
            usersData={usersData}
            cohortsData={cohortsData}
            indexBy={"user_id"}
            graphType="productivityGraph"
            productivityGraph
            isTeamSelected={isTeamSelected}
            userProfilesExtracted={
              organizationItemApplied?.team_group &&
              organizationItemApplied?.team_group[0]?.group_members.map(
                (group) => group.user_profile,
              )
            }
            organizationItemSelected={organizationItemSelected}
            cardContainerHeight={"444px"}
            graphContainerHeight={"444px"}
            handleSurveyClick={handleSurveyClick}
          />
        </div>
      </div>
    </div>
  );
};

export default Surveys;
