import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardGraph from "./DashboardGraph";
import { generateQueryForLineGraph } from "../../utilities/CubeUtils";
import { getGraphGranularityMappings } from "../../utilities/Utils";
import { secure_instance } from "../../axios/axios-config";

const ActiveTimePerTeam = () => {
  // const dispatch = useDispatch();
  const isTriggerRefetch = useSelector(
    (state) => state.dashboardReducer.isTriggerRefetch,
  );
  const dashboardGlobalFilters = useSelector(
    (state) => state.dashboardReducer.dashboardGlobalFilters,
  );
  const organizationGlobalFilter = useSelector(
    (state) => state.dashboardReducer.organizationGlobalFilter,
  );
  const user = useSelector((state) => state.authReducer.user);

  const [selectedGranularity, setSelectedGranularity] = useState("monthly");
  const [selectedGroupByOption, setSelectedGroupByOption] = useState("teams");
  const cumulativeGraphAnchorRef = useRef(null);
  const [isCumulativeGraphDropDownOpen, setCumulativeGraphDropDownOpen] =
    useState(false);

  const groupByAnchorEl = useRef(null);
  const [isGroupByMenuOpen, setIsGroupByMenuOpen] = useState(false);
  const [teamDataForMapping, setTeamDataForMapping] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [isGraphLoading, setIsGraphLoading] = useState(false);

  const handleGenericMenuOpen = (setIsGenericMenuOpen) => {
    setIsGenericMenuOpen((prevOpen) => !prevOpen);
  };
  const handleGenericMenuClose = (setIsGenericMenuOpen) => {
    setIsGenericMenuOpen(false);
  };

  const generateQuery = (selectedGranularity, groupByOption) => {
    console.log(selectedGranularity, groupByOption);
    // no need for this condition as only teams is avaiable
    if (groupByOption === "teams") {
      const modifyUserFiltersForLineGraph = JSON.parse(
        JSON.stringify(organizationGlobalFilter),
      );
      modifyUserFiltersForLineGraph.group = ["group_id"];
      const query = generateQueryForLineGraph(
        dashboardGlobalFilters,
        modifyUserFiltersForLineGraph,
        getGraphGranularityMappings(selectedGranularity),
        groupByOption,
      );
      const test = {
        ...query,
        fetch: {
          model: "Team",
          filters: [
            {
              col: "tenant_id",
              operator: "==",
              value: user.tenantId,
            },
          ],
        },
      };
      return test;
    }
    const query = generateQueryForLineGraph(
      dashboardGlobalFilters,
      organizationGlobalFilter,
      getGraphGranularityMappings(selectedGranularity),
      groupByOption,
    );
    return query;
  };

  const getGraphAPIData = async (query) => {
    try {
      const request = await secure_instance.request({
        url: "v1/graph/multiline",
        method: "POST",
        data: query,
      });
      return request.data;
    } catch (e) {
      console.error("Error fetching graph API data:", e);
      throw new Error("Failed to fetch graph API data");
    }
  };

  const getGraphData = async (query) => {
    setIsGraphLoading(true);
    try {
      const response = await getGraphAPIData(query);

      if (!response?.graph) {
        console.warn("No graph data available in response");
        return;
      }

      const { graph, addition_data } = response;

      const transformedGraph = graph[0]?.data
        .filter((item) => item.data.length > 0)
        .map((item) => ({
          data: item.data.map((innerItem) => ({
            y: innerItem.y,
            x: innerItem.x,
            group_id: innerItem.group_id,
          })),
          id: item.key,
        }));

      setTeamDataForMapping(addition_data);
      console.log("Transformed Graph:", transformedGraph);

      return transformedGraph;
    } catch (error) {
      console.error("Error in getGraphData:", error);
    } finally {
      setIsGraphLoading(false);
    }
  };

  const fetchGraphData = async () => {
    // setIsGraphLoading(true);
    const query = generateQuery(selectedGranularity, selectedGroupByOption);
    const graphData = await getGraphData(query);
    setGraphData(graphData ?? []);
    // setIsGraphLoading(false);
  };

  const handleGranularityOptionSelected = async (value) => {
    setSelectedGranularity(value);
    setCumulativeGraphDropDownOpen(false);

    const query = generateQuery(value, selectedGroupByOption);
    const graph = await getGraphData(query);

    setGraphData(graph);
  };

  const handleGroupByOptionSelected = async (value) => {
    setSelectedGroupByOption(value);
    setCumulativeGraphDropDownOpen(false);

    const query = generateQuery(selectedGranularity, value);
    const graph = await getGraphData(query);

    setGraphData(graph);
  };

  // Fetch data when component first loads
  useEffect(() => {
    fetchGraphData();
  }, []);

  // Fetch data on trigger refetch
  useEffect(() => {
    if (isTriggerRefetch) {
      fetchGraphData();
    }
  }, [isTriggerRefetch, selectedGranularity]);

  return (
    <DashboardGraph
      title="Active Time Per Team"
      isTriggerRefetch={isTriggerRefetch}
      dashboardGlobalFilters={dashboardGlobalFilters}
      isGraphLoading={isGraphLoading}
      graphData={graphData}
      setGraphData={setGraphData}
      getGraphData={getGraphData}
      generateQuery={generateQuery}
      selectedGranularity={selectedGranularity}
      setSelectedGranularity={setSelectedGranularity}
      teamDataForMapping={teamDataForMapping}
      cumulativeGraphAnchorRef={cumulativeGraphAnchorRef}
      handleGenericMenuOpen={handleGenericMenuOpen}
      handleGenericMenuClose={handleGenericMenuClose}
      handleGranularityOptionSelected={handleGranularityOptionSelected}
      handleGroupByOptionSelected={handleGroupByOptionSelected}
      groupByAnchorEl={groupByAnchorEl}
      isGroupByMenuOpen={isGroupByMenuOpen}
      setIsGroupByMenuOpen={setIsGroupByMenuOpen}
      isCumulativeGraphDropDownOpen={isCumulativeGraphDropDownOpen}
      setCumulativeGraphDropDownOpen={setCumulativeGraphDropDownOpen}
      groupByMenuItems={["Teams"]}
      selectedGroupByOption={selectedGroupByOption}
      ActiveTimePerTeam
    />
  );
};
export default ActiveTimePerTeam;
