import { createSlice } from "@reduxjs/toolkit";
import { secure_instance } from "../../axios/axios-config";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { showSnackbar } from "./authSlice";

const initialState = {
  departmentsData: [],
  selected: [],
  isMultipleRowsSelected: false,
  departmentCreationSuccess: false,
  departmentCreationFailure: false,
  departmentEditSuccess: false,
  departmentEditFailure: false,
  departmentDeletedSuccess: false,
  loading: false,
  tableData: {
    rowsData: [],
    rowsPerPage: 5,
    page: 1,
    selected: [],
    isMultipleRowsSelected: false,
    tableLoading: false,
  },
  sideMenu: {
    isSideMenuOpen: false,
    tableData: {
      usersData: [],
      rowsData: [],
      rowsPerPage: 5,
      page: 1,
      selected: [],
    },
  },
};

export const fetchDepartmentsData = createAsyncThunk(
  "departments/fetchDepartmentsData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await secure_instance.request({
        method: "GET",
        url: "v1/departments/tree",
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createNewDepartment = createAsyncThunk(
  "departments/createNewDepartment",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await secure_instance.request({
        method: "POST",
        url: "v1/departments/",
        data,
      });

      dispatch(fetchDepartmentsData());
      dispatch(departmentSlice.actions.toggleDepartmentsSideMenu());

      // Success alert
      dispatch(
        showSnackbar({
          message: "Department created successfully",
          severity: "success",
        }),
      );

      return response.data;
    } catch (error) {
      // Error alert
      dispatch(
        showSnackbar({
          message: "Failed to create department",
          severity: "error",
        }),
      );

      return rejectWithValue(error.response.data);
    }
  },
);

export const editDepartment = createAsyncThunk(
  "departments/editDepartment",
  async ({ data, departementID }, { dispatch, rejectWithValue }) => {
    try {
      const response = await secure_instance.request({
        method: "PATCH",
        url: `v1/departments/${departementID}`,
        data,
      });
      dispatch(fetchDepartmentsData());
      dispatch(departmentSlice.actions.toggleDepartmentsSideMenu());
      dispatch(
        showSnackbar({
          message: "Department edited successfully",
          severity: "success",
        }),
      );

      return response.data;
    } catch (error) {
      dispatch(
        showSnackbar({
          message: "Failed to edit department",
          severity: "error",
        }),
      );
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteDepartmentCall = createAsyncThunk(
  "departments/deleteDepartmentCall",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await secure_instance.request({
        url: "v1/departments/",
        method: "DELETE",
        data,
      });

      // Success alert
      dispatch(
        showSnackbar({
          message: "Department deleted successfully",
          severity: "success",
        }),
      );

      return response.data;
    } catch (error) {
      // Error alert
      dispatch(
        showSnackbar({
          message: "Failed to delete department",
          severity: "error",
        }),
      );

      return rejectWithValue(error.response.data);
    }
  },
);

const departmentSlice = createSlice({
  name: "departments",
  initialState,
  reducers: {
    // why this??
    saveFetchDepartmentsData(state, action) {
      state.departmentsData = action.payload;
    },
    handleUpdateDepartmentsData(state, action) {
      state.departmentsData.push(action.payload);
    },
    toggleLoading(state) {
      state.loading = !state.loading;
    },
    toggleDepartmentsSideMenu(state) {
      state.sideMenu.isSideMenuOpen = !state.sideMenu.isSideMenuOpen;
    },
    setTablePage: (state, action) => {
      state.tableData.page = action.payload;
    },
    setSideMenuTablePage: (state, action) => {
      state.sideMenu.tableData.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDepartmentsData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDepartmentsData.fulfilled, (state, action) => {
        state.departmentsData = action.payload;
        state.loading = false;
      })
      .addCase(fetchDepartmentsData.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createNewDepartment.pending, (state) => {
        state.loading = true;
      })
      .addCase(createNewDepartment.fulfilled, (state, action) => {
        // state.departmentsData = action.payload;
        // departmentSlice.actions.toggleDepartmentsSideMenu();
        state.loading = false;
      })
      .addCase(createNewDepartment.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteDepartmentCall.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteDepartmentCall.fulfilled, (state) => {
        state.loading = false;
        state.departmentDeletedSuccess = true;
      });
  },
});

export const {
  handleDepartmentsData,
  saveFetchDepartmentsData,
  handleUpdateDepartmentsData,
  toggleLoading,
  toggleDepartmentsSideMenu,
  setTablePage,
  setSideMenuTablePage,
} = departmentSlice.actions;

export default departmentSlice.reducer;
