import React, { useRef, useState } from "react";
import { capitalizeFirstAndRemoveLastTwoLetters } from "../../../utilities/Utils";
import PopperDropdown from "../../DropDowns/PopperDropdown";
import Field from "../../sub-components/Field";
import { DateRangePicker } from "../../DatePickers/DateRangePicker";
import dayjs from "dayjs";
import { surveyQuestionDummy } from "../../../utilities/dummyGraphs/surveyDummy";
import Button from "../../sub-components/Button";

const SideBarSurveys = () => {
  const [surveyTypeSelected, setSurveyTypeSelected] = useState("All Surveys");
  const cumulativeGraphAnchorRef = useRef(null);
  const [isCumulativeGraphDropDownOpen, setCumulativeGraphDropDownOpen] =
    useState(false);
  const [filterInputValue, setFilterInputValue] = useState("");
  const [startDate, setStartDate] = useState(
    dayjs().subtract(1, "week").format("YYYY-MM-DDTHH:mm:ss.SSS"),
  );
  const [endDate, setEndDate] = useState(
    dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
  );
  const [isDateMenuOpen, setIsDateMenuOpen] = useState(false);
  const [isCurrentSurvey, setIsCurrentSurvey] = useState({});

  const handleGenericMenuOpen = (setIsGenericMenuOpen) => {
    setIsGenericMenuOpen((prevOpen) => !prevOpen);
  };
  const handleGenericMenuClose = (setIsGenericMenuOpen) => {
    setIsGenericMenuOpen(false);
  };
  const handleOptionSelected = (e) => {
    console.log(e.target.id);
    setSurveyTypeSelected(e.target.id);
    setCumulativeGraphDropDownOpen(false);
  };

  const fetchDatesData = async (selectedStartDate, selectedEndDate) => {
    setStartDate(selectedStartDate);
    setEndDate(selectedEndDate);
  };

  const getGradeBackgroundColors = (grade) => {
    if (grade === "A") {
      return "#00E13218";
    }
    if (grade === "B") {
      return "#117AF418";
    }
    if (grade === "C") {
      return "#EB2D1E18";
    }
  };

  const getGradeTextColors = (grade) => {
    if (grade === "A") {
      return "#00E132";
    }
    if (grade === "B") {
      return "#117AF4";
    }
    if (grade === "C") {
      return "#EB2D1E";
    }
  };

  return (
    <>
      {console.log("isCurrentSurveyisCurrentSurvey", isCurrentSurvey)}
      {Object.keys(isCurrentSurvey).length === 0 &&
      isCurrentSurvey.constructor === Object ? (
        <>
          <div
            className="row"
            style={{
              background: "#F4E6FFCC",
              padding: "20px 24px",
              marginTop: "32px",
            }}
          >
            <div className="d-flex w-100 justify-content-between">
              <span className="head6-medium">John Doe’s Surveys</span>
              {/* <span>New Survey</span> */}
              <Button
                buttonAddtoTeam
                title="New Survey"
                // onClick={() => handleCreateTeamButton()}
                style={{
                  boxShadow: "none",
                  // width: "100%",
                  padding: "0 30px",
                  cursor: "pointer",
                }}
              />
            </div>

            <div
              className="w-100 row justify-content-between"
              style={{
                padding: "0 8px",
                marginTop: "32px",
                paddingBottom: "28px",
              }}
            >
              <div
                className="col-lg-3"
                style={{
                  borderRight: "4px solid #9300FF",
                  // margin: "0 48px",
                  height: "100%",
                  width: "4px",
                }}
              >
                <span className="d-flex head1-medium mb-3">7</span>
                <span className="d-flex body1-medium grey6">Total Surveys</span>
                {/* <div
                  style={{
                    borderRight: "4px solid #9300FF",
                    margin: "0 48px",
                    height: "100%",
                    width: "4px",
                  }}
                /> */}
              </div>

              {/* <div className="col-lg-1">
                <div
                  // className="d-flex flex-column"
                  style={{
                    border: "4px solid #9300FF",
                    borderRadius: "10px",
                    margin: "0 48px",
                    height: "100%",
                    width: "4px",
                  }}
                />
              </div> */}
              <div
                className="col-lg-3"
                style={{
                  borderRight: "4px solid #9300FF",
                  // margin: "0 48px",
                  height: "100%",
                  width: "4px",
                }}
              >
                <div className="ml-5">
                  <span className="d-flex head1-medium mb-3">5</span>
                  <span className="d-flex body1-medium grey6">Answered</span>
                </div>
              </div>
              {/* <div className="col-lg-1">
                <div
                  // className="d-flex flex-column"
                  style={{
                    border: "4px solid #9300FF",
                    borderRadius: "10px",
                    margin: "0 48px",
                    height: "100%",
                    width: "4px",
                  }}
                />
              </div> */}
              <div
                className="col-lg-3"
                style={{
                  borderRight: "4px solid #9300FF",
                  // margin: "0 48px",
                  height: "100%",
                  width: "4px",
                }}
              >
                <div className="ml-5">
                  <div className="d-flex head1-medium mb-3 ">2</div>
                  <span className="d-flex body1-medium grey6">Unanswered</span>
                </div>
              </div>
              {/* <div className="col-lg-1">
                <div
                  // className="d-flex flex-column"
                  style={{
                    border: "4px solid #9300FF",
                    borderRadius: "10px",
                    margin: "0 48px",
                    height: "100%",
                    width: "4px",
                  }}
                />
              </div> */}
              <div className="col-lg-3">
                <div className="ml-5">
                  <span className="d-flex head1-medium mb-3">1</span>
                  <span className="d-flex body1-medium grey6">
                    Due in 3 days
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            className="row d-flex justify-content-between"
            style={{ marginTop: "32px" }}
          >
            <div className="d-flex align-items-center flex-row">
              <PopperDropdown
                anchorRef={cumulativeGraphAnchorRef}
                isDropdownOpen={isCumulativeGraphDropDownOpen}
                handleToggle={() =>
                  handleGenericMenuOpen(setCumulativeGraphDropDownOpen)
                }
                handleClose={() =>
                  handleGenericMenuClose(setCumulativeGraphDropDownOpen)
                }
                dropdownDisplayText={surveyTypeSelected}
                menuItems={[
                  "All Surveys",
                  "Performance Surveys",
                  "Wellness Surveys",
                ]}
                handleOptionSelected={handleOptionSelected}
                activeMenuItem={surveyTypeSelected}
              />
              <div style={{ marginLeft: "16px" }} />
              <Field
                inputFieldFullSearchIcon
                placeholder={`Search`}
                value={filterInputValue}
                onChange={(e) => setFilterInputValue(e.target.value)}
                style={{
                  width: "100%",
                  height: "40px",
                  paddingLeft: "52px",
                  borderRight: "1px solid #e9ecef",
                  borderRadius: "10px",
                  background: "white",
                }}
              />
            </div>

            <DateRangePicker
              value={{ from: startDate, to: endDate }}
              fetchDatesData={fetchDatesData}
              setIsDateMenuOpen={setIsDateMenuOpen}
              isDateMenuOpen={isDateMenuOpen}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </div>

          <div style={{ marginTop: "24px" }} className="row">
            {surveyQuestionDummy.map((survey, index) => {
              return (
                <div
                  key={index}
                  className="col-lg-12"
                  style={{
                    border: "3px solid #F5F5F5",
                    boxShadow: "0px 4px 4px 0px #FAFAFA",
                    marginBottom: "24px",
                  }}
                >
                  <div className="head6-medium" style={{ margin: "19px 0" }}>
                    {survey.category}
                  </div>
                  {survey.surveys.map(
                    (surveyQuestions, surveyQuestionsIndex) => {
                      return (
                        <div
                          key={surveyQuestionsIndex}
                          className="d-flex justify-content-between col-lg-12"
                          style={{
                            border: "1px solid #DEDEDE",
                            borderRadius: "10px",
                            padding: "12px 11px",
                            marginBottom: "19px",
                          }}
                        >
                          <div className="d-flex">
                            <div
                              className="d-flex justify-content-between flex-column align-items-center"
                              style={{
                                background: getGradeBackgroundColors(
                                  surveyQuestions.grade,
                                ),
                                marginRight: "26px",
                                width: "92px",
                                padding: "10px 0",
                              }}
                            >
                              <span
                                className="d-flex head3-semi-bold mb-2"
                                style={{
                                  color: getGradeTextColors(
                                    surveyQuestions.grade,
                                  ),
                                }}
                              >
                                {surveyQuestions.grade}
                              </span>

                              <span
                                className="d-flex head6-medium"
                                style={{
                                  color: getGradeTextColors(
                                    surveyQuestions.grade,
                                  ),
                                }}
                              >
                                {surveyQuestions.pecentage}
                              </span>
                            </div>
                            <div
                              className="d-flex justify-content-between flex-column"
                              style={{ padding: "14px 0" }}
                            >
                              <div className="d-flex head5-medium grey9">
                                {surveyQuestions.surveyQuestion}
                              </div>
                              <span
                                className="d-flex primary-color caption1"
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  setIsCurrentSurvey(surveyQuestions)
                                }
                              >
                                {"See Responses"}
                              </span>
                            </div>
                          </div>

                          <div
                            className="d-flex justify-content-between flex-column"
                            style={{ padding: "20px 0" }}
                          >
                            <div className="d-flex caption1 grey9">{`Asked By: ${surveyQuestions.AskedBy}`}</div>
                            <span className="d-flex caption1 grey9">{`Created at: ${surveyQuestions.CreatedAt}`}</span>
                          </div>
                          {/* <span>{"See Responses"}</span> */}
                        </div>
                      );
                    },
                  )}
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <div
          style={{
            border: "3px solid #F5F5F5",
            boxShadow: "0px 4px 4px 0px #FAFAFA",
            borderRadius: "10px",
            padding: "20px 34px",
          }}
        >
          <div className="head6-medium" style={{ marginBottom: "19px" }}>
            Survey Question
          </div>
          <div
            // key={surveyQuestionsIndex}
            className="d-flex justify-content-between col-lg-12"
            style={{
              border: "1px solid #DEDEDE",
              borderRadius: "10px",
              padding: "12px 11px",
              marginBottom: "19px",
            }}
          >
            <div className="d-flex">
              <div
                className="d-flex justify-content-between flex-column align-items-center"
                style={{
                  background: getGradeBackgroundColors(isCurrentSurvey.grade),
                  marginRight: "26px",
                  width: "92px",
                  padding: "10px 0",
                }}
              >
                <span
                  className="d-flex head3-semi-bold mb-2"
                  style={{
                    color: getGradeTextColors(isCurrentSurvey.grade),
                  }}
                >
                  {isCurrentSurvey.grade}
                </span>

                <span
                  className="d-flex head6-medium"
                  style={{
                    color: getGradeTextColors(isCurrentSurvey.grade),
                  }}
                >
                  {isCurrentSurvey.pecentage}
                </span>
              </div>
              <div
                className="d-flex justify-content-between flex-column"
                style={{ padding: "14px 0", marginTop: "6px" }}
              >
                <div className="d-flex head5-medium grey9">
                  {isCurrentSurvey.surveyQuestion}
                </div>
              </div>
            </div>

            <div
              className="d-flex justify-content-between flex-column"
              style={{ padding: "20px 0" }}
            >
              <div className="d-flex caption1 grey9">{`Asked By: ${isCurrentSurvey.AskedBy}`}</div>
              <span className="d-flex caption1 grey9">{`Created at: ${isCurrentSurvey.CreatedAt}`}</span>
            </div>
          </div>
          <div className="head6-medium mb-2">Responses</div>
          <div>
            <div
              className="d-flex justify-content-between"
              style={{
                border: "1px solid #DEDEDE",
                padding: "16px",
                borderRadius: "6px 6px 0px 0px",
              }}
            >
              <span className="col-lg-3 body1-medium">Full Name</span>
              <span className="col-lg-3 body1-medium d-flex justify-content-center">
                Response
              </span>
              <span className="col-lg-3 body1-medium d-flex justify-content-center">
                Job Title
              </span>
              <span className="col-lg-3 body1-medium d-flex justify-content-center">
                Response Submitted
              </span>
            </div>
            <div
              className="d-flex justify-content-between align-items-center"
              style={{
                border: "1px solid #DEDEDE",
                padding: "16px",
                borderTop: "none",
              }}
            >
              <span className="body1 col-lg-3 d-flex align-items-center">
                Savannah Nguyen
              </span>
              <span className="body1 col-lg-3 d-flex justify-content-center">
                😍
              </span>
              <span className="body1 col-lg-3 d-flex justify-content-center">
                Senior Engineer
              </span>
              <span className="body1 col-lg-3 d-flex justify-content-center">
                5/27/15
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SideBarSurveys;
