import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { ReactComponent as Arrow_left } from "../../assets/icons/ArrowLeft.svg";
import Button from "../../components/sub-components/Button";
import Switch from "../sub-components/Switch";
import Field from "../sub-components/Field";
import LoadingAlert from "../Loading/LoadingAlert";
import CustomModal from "../Custom/Modal/Modal";
import { Table } from "../Tables/Table";
import { ReactComponent as AddToTeamPopUp } from "../../assets/icons/AddToTeamPopUp.svg";
import { ReactComponent as Plus } from "../../assets/icons/Plus.svg";
import { ReactComponent as X } from "../../assets/icons/X_RemoveTeams.svg";
import { ReactComponent as MinusCheckbox } from "../../assets/icons/MinusCheckbox.svg";
import {
  checkCurrentProviderStatus,
  getAutoSyncUsers,
  getGroupsSyncData,
  handleFilteredTeamsGroupData,
  handleManualSyncUsers,
  handleSyncUsers,
  handleSyncUsersEnd,
  handleTeamsGroupPost,
  setTablePage,
  toggleAutoSyncButton,
  toggleAutoSyncGroupsButton,
  toggleSyncMenu,
} from "../../redux/slices/identityProviderSlice";

const columns = [
  {
    key: "fullName",
    // numeric: false,
    // disablePadding: true,
    name: "Name",
    minWidth: 200,
  },
  {
    key: "primaryEmail",
    // numeric: false,
    minWidth: 100,
    padding: "0 32px 0 0",
    // disablePadding: false,
    align: "right",
    name: "Job Title",
  },
  // {
  // 	id: "action",
  // 	numeric: true,
  // 	label: "Action",
  // },
];

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
    color: "none",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "none",
  },
}));

export default function Sync(props) {
  const dispatch = useDispatch();
  const { handleTeamsPopup } = props;
  const [inputValue, setInputValue] = useState("");
  const [selected, setSelected] = useState([]);
  const { showSyncMenu } = props;
  const [isTeamRemove, setIsTeamRemove] = React.useState(false);

  const history = useHistory();
  const { loading, tableLoader } = useSelector(
    (state) => state.identityProviderReducer.ProviderConnectionInfo,
  );
  const { page } = useSelector(
    (state) => state.identityProviderReducer.sideMenuTableData,
  );

  const [open, setOpen] = React.useState(false);
  const [currentRow, setCurrentRow] = React.useState({});
  const [groupName, setGroupName] = React.useState("");
  const [teamName, setTeamName] = React.useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const {
    autoSyncSwitch,
    autoSync,
    loadingAlert,
    teamsData,
    groupsData,
    autoSyncGroupsSwitch,
    enableAutoSync,
    syncUsersData,
    providersName,
  } = useSelector(
    (state) =>
      state.identityProviderReducer.ProviderConnectionInfo.IdentityProvders,
  );

  const groud_id = [];

  const teamsColumns = [
    {
      key: "name",
      name: "Groups",
      padding: "0 0 0 32px",
      minWidth: 100,
      accessor: (row) => {
        const isAddedAsTeams = filteredTeamsAlreadyAdded(row);
        return (
          <>
            <span className="b1" style={{ display: "flex" }}>
              {row.name}
            </span>
            <div>
              {isAddedAsTeams?.length > 0 && (
                <div className="added-banner s2 primary-color">
                  <span
                    className="mr-1"
                    style={{
                      display: "inline-block",
                      wordBreak: "break-all",
                      whiteSpace: "normal",
                      width: "100%",
                    }}
                  >
                    Added as {isAddedAsTeams[0].name}
                  </span>
                  <X
                    style={{
                      cursor: "pointer",
                      minWidth: "16px",
                      minHeight: "16px",
                    }}
                    onClick={() => handleRemoveFromTeam(row)}
                  />
                </div>
              )}
            </div>
          </>
        );
      },
    },
    {
      key: "action",
      minWidth: 100,
      padding: "0 32px 0 0",
      align: "right",
      name: "Action",
      accessor: (row) => (
        <div>
          {teamsData.map((team) => {
            groud_id.push(team?.group_id);
          })}

          {groud_id?.includes(row?.id) ? (
            // <div style={{display:"flex"}}>
            <MinusCheckbox
              onClick={() => handleRemoveFromTeam(row)}
              style={{ borderRadius: "2px", cursor: "pointer" }}
            />
          ) : (
            // </div>
            <CustomTooltip
              title={<AddToTeamPopUp />}
              placement="top"
              disableInteractive
            >
              <Plus
                stroke="black"
                onClick={(e) => {
                  handleTeamsPopup(row, e);
                }}
                style={{ cursor: "pointer" }}
              />
            </CustomTooltip>
          )}
        </div>
      ),
    },
  ];

  // Ref for the most recent value of autoSync
  const countRef = useRef(autoSync);
  countRef.current = autoSync;

  const onAutoSyncChange = () => {
    // if (autoSyncSwitch) {
    //   dispatch(toggleAutoSyncButton("disable", providersName));
    // } else {
    //   dispatch(toggleAutoSyncButton("enable", providersName));
    // }
    dispatch(
      toggleAutoSyncButton({
        autoSyncEnabled: autoSyncSwitch ? false : true,
      }),
    );
    // if (autoSyncSwitch)
    if (syncUsersData.length === 0) {
      dispatch(getAutoSyncUsers(providersName));
    }
    // if (autoSyncSwitch) {
    //   dispatch(
    //     identityProvidersActions.toggleAutoSyncButton("disable", providersName),
    //   );
    // } else {
    //   dispatch(
    //     identityProvidersActions.toggleAutoSyncButton("enable", providersName),
    //   );
    // }
  };

  const onAutoSyncGroupChange = () => {
    if (autoSyncGroupsSwitch) {
      dispatch(toggleAutoSyncGroupsButton("disable"));
    } else {
      dispatch(toggleAutoSyncGroupsButton("enable"));
    }
  };

  const getAutoSync = () => {
    if (countRef.current) {
      return;
    }
    if (!countRef.current && enableAutoSync) {
      dispatch(checkCurrentProviderStatus(providersName));
      setTimeout(getAutoSync, 3000);
    }
  };

  const handleSyncNext = () => {
    if (autoSyncSwitch) {
      dispatch(handleSyncUsers(providersName));
    }
    if (!autoSyncSwitch) {
      getManualSyncUsers();
    }
    handleSetTablePage(1);
  };

  const getManualSyncUsers = () => {
    const map = { users: selected };
    dispatch(handleManualSyncUsers({ map, providersName, history }));
  };

  const handleTeamsGroupMapping = () => {
    if (autoSyncGroupsSwitch) {
      const allGroupsAsTeams = groupsData.map((group) => ({
        tenant_id: group.tenant_id,
        name: group.name,
        group_id: group.id,
      }));

      const data = { teams: allGroupsAsTeams };
      dispatch(handleTeamsGroupPost({ data, history }));
      return;
    }
    const data = { teams: teamsData };
    dispatch(handleTeamsGroupPost({ data, history }));
  };

  const disableOverlay = () => {
    return (
      <div
        style={{
          position: "absolute",
          // left: 0,
          // bottom:0,
          width: "100%",
          padding: "10px",
          height: "100%",
          backgroundColor: "rgb(248,249,250,0.6)",
          borderRadius: "10px",
          zIndex: 5,
        }}
      />
    );
  };

  const handleSetTablePage = (page) => {
    dispatch(setTablePage(page));
  };

  const filteredTeamsAlreadyAdded = (row) => {
    if (teamsData.length > 0) {
      const filtered = teamsData.filter(
        (team) => team?.group_id && team?.group_id === row?.id,
      );
      return filtered !== undefined && filtered;
    }
  };

  const handleRemoveFromTeam = (row) => {
    if (isTeamRemove) {
      const filteredTeamsData = JSON.parse(JSON.stringify(teamsData));
      const filtered = filteredTeamsData.filter(
        (team) => team?.group_id !== currentRow?.id,
      );
      dispatch(handleFilteredTeamsGroupData(filtered));
      handleClose();
      setIsTeamRemove(false);
    }
    if (row) {
      const TeamsData = JSON.parse(JSON.stringify(teamsData));
      TeamsData.map((team) => {
        if (team?.group_id === row?.id) {
          setTeamName(team.name);
        }
      });
      setGroupName(row.name);
      setCurrentRow(row);
      handleOpen();
    }
  };

  useEffect(() => {
    if (autoSync) {
      dispatch(getGroupsSyncData(history));
      dispatch(handleSyncUsersEnd());
    }
  }, [autoSync]);

  useEffect(() => {
    getAutoSync();
  }, [enableAutoSync]);

  useEffect(() => {
    if (isTeamRemove) {
      handleRemoveFromTeam();
    }
  }, [isTeamRemove]);

  return (
    <div
      className={`side-menu-box ${
        showSyncMenu === true ? "open border" : "side-menu-box border"
      }`}
    >
      <div className="d-flex justify-content-between">
        <span className="head5 grey8">
          {/* <Close style={{margin:"2px 	10px 5px 0",width:"24px",cursor:"pointer"}}  */}
          <Arrow_left
            stroke="#343A40"
            style={{ margin: "2px 10px 5px 0", width: "24px" }}
            onClick={
              autoSync && groupsData.length > 0
                ? () => props.handleCancelSetup()
                : () => dispatch(toggleSyncMenu())
            }
          />
          {autoSync ? "Create Teams" : "User Settings"}
        </span>
        {loading && !autoSync && loadingAlert && autoSyncSwitch && (
          <LoadingAlert />
        )}
        <div
          className="d-flex align-items-center flex-row-reverse"
          style={{ width: "fit-content" }}
        >
          <div className="integration-menu-buttons" style={{ width: "80px" }}>
            <Button
              typography={"b0-medium grey1"}
              buttonFilledFull
              title={autoSync ? "Sync" : "Next"}
              // style={{ color: "#F8F9FA", height: "40px",marginTop:"5px",fontWeight:"400" }}
              loading={loading}
              onClick={
                autoSync === null || !autoSync
                  ? () => handleSyncNext()
                  : () => handleTeamsGroupMapping()
              }
            />
          </div>
          <div
            className="integration-menu-buttons"
            style={{ paddingRight: "15px" }}
          >
            <Button
              typography={"s1 grey6"}
              buttonHollowLg
              title="Cancel"
              style={{ height: "40px", color: "#6C757D" }}
              onClick={() => props.handleCancelSetup()}
            />
          </div>
        </div>
      </div>
      {/* <div className="top-border" /> */}
      <div
        className="head6 grey9"
        style={{ paddingTop: "30px", paddingBottom: "30px" }}
      >
        {autoSync ? "Create Teams automatically" : "Syncing Settings"}
      </div>

      {!autoSync && groupsData.length === 0 && (
        <div
          className="sync-menu-options-container"
          style={{ border: "1px solid #E9ECEF", borderRadius: "10px" }}
        >
          <div style={{ marginBottom: "5px" }}>
            <div
              className="d-flex flex-row justify-content-between"
              style={{ minHeight: "20px" }}
            >
              <div className="b1 grey8">Auto Sync Users and Groups</div>
              <Switch
                id="auto-sync"
                checked={autoSyncSwitch}
                onChange={() => onAutoSyncChange}
              />
            </div>
            <span className="b2 grey6">
              Automatically sync users and groups
            </span>
            <div className="b2 b2-mobile" style={{ color: "#6C757D" }}></div>
          </div>
        </div>
      )}
      {autoSync && groupsData && (
        <div className="sync-menu-options-container">
          <div style={{ marginBottom: "5px" }}>
            <div
              className="d-flex flex-row justify-content-between"
              style={{ minHeight: "20px" }}
            >
              <div className="b1 grey8">Add all active groups as teams</div>
              <Switch
                id="auto-sync"
                checked={autoSyncGroupsSwitch}
                onChange={onAutoSyncGroupChange}
              />
            </div>
            <div className="b2 grey6">
              You can make changes once your platform is set up
            </div>
          </div>
        </div>
      )}

      {autoSyncSwitch && !autoSync && (
        <span className="b3 grey6" style={{ marginTop: "12px" }}>
          To add users manually, disable auto sync
        </span>
      )}
      {!autoSyncSwitch && !autoSync && (
        <>
          <div
            className="head6"
            style={{ paddingTop: "30px", paddingBottom: "30px" }}
          >
            Add Users Manually
          </div>

          <div className="sync-menu-search-field d-flex align-items-center s1">
            <Field
              inputFieldFullSearchIcon
              placeholder="Search"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              style={{ width: "100%", paddingLeft: "52px", height: "40px" }}
            />
            <Button
              buttonHollowSm
              title="Filters"
              style={{ borderRadius: "0 10px 10px 0" }}
            />
          </div>

          {console.log("tableLoader", tableLoader)}

          {!autoSync && (
            <Table
              data={syncUsersData?.users}
              loading={tableLoader}
              inputValue={inputValue}
              columns={columns}
              // filters={filters}
              autoSync={autoSync}
              page={page}
              setMultipleSelected={() => {}}
              setSelected={setSelected}
              selected={selected}
              setTablePage={handleSetTablePage}
              syncingSettings
              checkboxSize="24px"
              checkboxRequired
              displayAvatar
              disableToolbar
            />
          )}
        </>
      )}
      {autoSync && groupsData && (
        <div style={{ position: "relative" }}>
          <div
            className="head6 grey9"
            style={{
              color: autoSyncGroupsSwitch && "#ADB5BD",
              marginTop: "30px",
              paddingBottom: "30px",
            }}
          >
            Create Teams manually
          </div>

          <div className="sync-menu-search-field d-flex align-items-center s1">
            <div style={{ position: "relative" }}>
              {autoSyncGroupsSwitch && disableOverlay()}
              <Field
                inputFieldFullSearchIcon
                placeholder="Search"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                style={{ width: "100%", paddingLeft: "52px", height: "40px" }}
              />
            </div>
            <div style={{ position: "relative" }}>
              {autoSyncGroupsSwitch && disableOverlay()}
              <Button
                typography={"s2 grey6"}
                buttonHollowSm
                title="Filters"
                style={{ borderRadius: "0 10px 10px 0" }}
              />
            </div>
          </div>
          {/* <TeamsGroupsTable
					autoSyncGroups={autoSyncGroupsSwitch}
					disableOverlay={disableOverlay}
					inputValue={inputValue}
					syncGroupsData={groupsData}
					handleTeamsPopup={handleTeamsPopup}
				/> */}
          <CustomModal
            groupName={groupName}
            open={open}
            teamName={teamName}
            removePopUpHandler={() => setIsTeamRemove(true)}
            heading="Remove as team"
            text={`Are you sure you want to remove group ${groupName} as a team ${teamName}`}
            leftButton="Cancel"
            rightButton="Remove"
            style={{ width: "611px", height: "250px" }}
            handleModalClose={handleClose}
            identityProvidersTeamsModal
          />
          <div style={{ position: "relative" }}>
            {autoSyncGroupsSwitch && disableOverlay()}
            <Table
              data={groupsData}
              loading={tableLoader}
              inputValue={inputValue}
              columns={teamsColumns}
              teamsData={teamsData}
              page={page}
              setMultipleSelected={() => {}}
              setTablePage={handleSetTablePage}
              filteredTeamsAlreadyAdded={filteredTeamsAlreadyAdded}
              handleTeamsPopup={handleTeamsPopup}
              handleRemoveFromTeam={handleRemoveFromTeam}
              isDisabled={autoSyncGroupsSwitch}
              disableOverlay={disableOverlay}
              displayAvatar
            />
          </div>
        </div>
      )}
      <div className="integration-menu-buttons-container-mobile">
        {/* --------------------------- MOBILE VIEW --------------------------- */}
        <div
          className="integration-menu-buttons-mobile"
          style={{ width: "100%" }}
        >
          <Button
            buttonFilledFull
            title={autoSync ? "Sync" : "Next"}
            style={{
              color: "white",
              height: "40px",
              marginTop: "5px",
              fontWeight: "400",
            }}
            loading={loading}
            onClick={
              autoSync === null
                ? () => handleSyncNext()
                : () => handleTeamsGroupMapping()
            }
          />
        </div>
        <div
          className="integration-menu-buttons-mobile"
          style={{ width: "100%" }}
        >
          <Button
            buttonHollowLg
            title="Cancel"
            style={{ width: "100%", height: "40px", marginTop: "15px" }}
            onClick={() => props.handleCancelSetup()}
          />
        </div>
      </div>
    </div>
  );
}
