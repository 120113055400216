import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { MenuItem } from "@mui/material";
import { ReactComponent as DotsThreeHorizontal } from "../../../../assets/icons/DotsThreeHorizontal.svg";
import { ReactComponent as Fi_trash } from "../../../../assets/icons/fi_trash.svg";
import { ReactComponent as PencilSimple } from "../../../../assets/icons/PencilSimple.svg";
import { StyledMenu } from "../../../../components/Menus/Menu";
import { setSideMenuTablePage } from "../../../../redux/slices/departmentSlice";
import Button from "../../../../components/sub-components/Button";

const ActionButtons = ({
  handleSideMenuClose,
  data,
  isRowClicked,
  isEditAllowed,
  setIsEditAllowed,
  isConfirmationModal,
  setIsConfirmationModal,
  setSelectedTab,
  handleCreateOKR,
}) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.objectivesReducer.sideMenu);

  const [anchorEl, setAnchorEl] = useState(null);
  const [nextActive, setNextActive] = useState(false);

  const isButtonActionMenu = Boolean(anchorEl);

  const nextStyle = {
    backgroundColor: nextActive ? "#9300FF" : "#7B2CBF66",
    cursor: nextActive ? "pointer" : "no-drop",
  };

  const handleThreeDotsOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleThreeDotsClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const { objectiveForm, keyResultsList, addPeopleToObjectiveSelected } =
      data;

    const allKeyResultsFilled = keyResultsList.every(
      (keyResult) =>
        keyResult.name !== "" &&
        keyResult.threshold_type !== "" &&
        keyResult.threshold_value !== "" &&
        keyResult.KeyResultFor.length > 0,
    );

    const allFieldsFilled =
      objectiveForm.name !== "" &&
      objectiveForm.owner !== "" &&
      objectiveForm.description !== "" &&
      objectiveForm.start_date !== null &&
      objectiveForm.end_date !== null &&
      addPeopleToObjectiveSelected.length > 0 &&
      allKeyResultsFilled;

    setNextActive(allFieldsFilled);
  }, [data]);

  if (isRowClicked && !isEditAllowed) {
    return (
      <>
        <div
          style={{
            cursor: "pointer",
            width: "40px",
            height: "40px",
          }}
        >
          <DotsThreeHorizontal onClick={handleThreeDotsOpen} />
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={isButtonActionMenu}
            onClose={handleThreeDotsClose}
          >
            <MenuItem
              disableRipple
              onClick={() => {
                // deleteDepartment();
              }}
            >
              <Fi_trash
                stroke="#343A40"
                style={{ width: "16px", height: "16px" }}
              />
              <span
                className="s2 grey8 d-flex align-items-center"
                style={{ marginLeft: "8px" }}
              >
                Delete
              </span>
            </MenuItem>
          </StyledMenu>
        </div>
        <div style={{ marginRight: "16px" }}>
          <PencilSimple
            style={{
              width: "32px",
              height: "32px",
              cursor: "pointer",
            }}
            onClick={() => setIsEditAllowed(true)}
          />
        </div>
      </>
    );
  }

  if (!isConfirmationModal) {
    return (
      <>
        <Button
          buttonFilledLg
          typography="body1-medium grey0"
          title="Next"
          loading={loading}
          style={nextStyle}
          onClick={() => {
            setIsConfirmationModal(true);
            dispatch(setSideMenuTablePage(1));
            setSelectedTab && setSelectedTab({ id: 1 });
          }}
          disabled={!nextActive}
        />
        <Button
          buttonHollowLg
          typography="s1 grey6"
          title="Cancel"
          onClick={handleSideMenuClose}
        />
      </>
    );
  } else {
    return (
      <>
        <Button
          buttonFilledLg
          typography="body1-medium grey0"
          title="Confirm"
          loading={loading}
          style={nextStyle}
          onClick={handleCreateOKR}
          disabled={!nextActive}
        />
        <Button
          buttonHollowLg
          typography="s1 grey6"
          title="Cancel"
          onClick={handleSideMenuClose}
        />
      </>
    );
  }
};

export default ActionButtons;
