export const setCookie = function (cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);

  const expires = "expires=" + d.toUTCString();
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

export const getCookie = function (cname) {
  const ca = document.cookie.split(";");

  const token = ca.find((c) => {
    return c.replace(" ", "").split("=")[0] === cname;
  });

  if (!token) {
    return "";
  }

  return token.split("=")[1];
};

export const deleteCookie = function (cname) {
  // const d = new Date();
  // d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);

  const expires = "expires=" + "Thu, 01 Jan 1970 00:00:00 GMT";
  document.cookie = `${cname}=${""};${expires};path=/`;
};

export const capitalizeFirstLetter = (string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
};

export const capitalizeFirstAndRemoveLastTwoLetters = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1, -2);
};

export const getGraphGranularityMappings = (selectedGranularity) => {
  switch (selectedGranularity) {
    case "users":
      return selectedGranularity.slice(0, -1);

    case "teams":
      return selectedGranularity.slice(0, -1);

    case "cohorts":
      return selectedGranularity.slice(0, -1);
    case "daily":
      return "day";
  }
  return selectedGranularity.slice(0, -2);
  //  if(selectedGranularity === "daily"){
  //   return "day"
  //  }
};

export const detectDateFormat = (data, communicationGraph) => {
  if (!data || data?.length == 0) {
    // No data to analyze
    return null;
  }
  // console.log("vdata", data[0]);

  const firstDate = data[0]?.data[0]?.x; // Assuming 'y' is the key holding the date string
  // const firstDate = communicationGraph
  //   ? data[0][0].data[0].x
  //   : data[0].data[0].x; // Assuming 'y' is the key holding the date string

  if (/^\d{4}-\d{2}-\d{2}$/.test(firstDate)) {
    return "YYYY-MM-DD"; // Date format without time
  } else if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/.test(firstDate)) {
    return "YYYY-MM-DDTHH:mm:ss"; // Date format with time
  } else {
    // Unsupported format or invalid date
    return null;
  }
};

export const getFormatForCurrentGranularity = (granularity) => {
  // console.log("granularitygranularity", granularity);
  if (granularity === "second") {
    return ":%S";
  }
  if (granularity === "minute") {
    return "%I:%M";
  }
  if (granularity === "hour") {
    return "%I %p";
  }
  if (granularity === "day") {
    return "%a %d";
  }
  if (granularity === "week") {
    return "%b %d";
  }
  if (granularity === "month") {
    return "%B";
  }
  if (granularity === "year") {
    return "%Y";
  }
};

export const getTickValueIntervals = (granularity) => {
  // if (granularity === "second") {
  //   return ":%S";
  // }
  // if (granularity === "minute") {
  //   return "%I:%M";
  // }
  if (granularity === "hour") {
    // return "every 10 hours";
    return "every hour";
  }
  if (granularity === "day") {
    return "every day";
  }
  if (granularity === "week") {
    return "every week";
  }
  if (granularity === "month") {
    return "every month";
  }
  if (granularity === "year") {
    return "every 1 year";
  }
};

export const emptyRows = (page, rowsPerPage, data) =>
  page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

export const toggleCheckboxSelection = (
  selectedItems,
  itemToToggle,
  setFunction,
  identifier,
) => {
  const findFunction = (x) => {
    if (
      identifier &&
      Object.prototype.hasOwnProperty.call(x, identifier) &&
      Object.prototype.hasOwnProperty.call(itemToToggle, identifier)
    ) {
      return x[identifier] === itemToToggle[identifier];
    } else {
      return x === itemToToggle;
    }
  };

  const selectedIndex = selectedItems.findIndex(findFunction);

  let newSelected = [];

  if (selectedIndex === -1) {
    // Item not currently selected, add it to the selection
    newSelected = [...selectedItems, itemToToggle];
  } else {
    // Item currently selected, remove it from the selection
    newSelected = [
      ...selectedItems.slice(0, selectedIndex),
      ...selectedItems.slice(selectedIndex + 1),
    ];
  }

  setFunction(newSelected);
};

export const getSurveyGradeBackgroundColors = (grade) => {
  if (grade === "A") {
    return "#00E13218";
  }
  if (grade === "B") {
    return "#117AF418";
  }
  if (grade === "C") {
    return "#EB2D1E18";
  }
};

export const getSurveyGradeTextColors = (grade) => {
  if (grade === "A") {
    return "#00E132";
  }
  if (grade === "B") {
    return "#117AF4";
  }
  if (grade === "C") {
    return "#EB2D1E";
  }
};

export const compareIds = (item1, item2) => {
  const id1 = item1.user_id ?? item1.id;
  const id2 = item2.user_id ?? item2.id;
  return id1 === id2;
};
export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const getExcludedPathsForHeader = () => {
  const excludedPaths = [
    "/",
    "/login",
    "/signup",
    "/forgot-password",
    "/activation",
    "/activation/:id",
    "/confirm-plan",
    "/join-organization",
  ];
  return excludedPaths;
};

export const integrationsDescriptions = {
  Slack: {
    description:
      "Integrate your workspace with Slack to improve team communication, collaboration, and efficiency. By connecting Slack, you'll gain access to features that help streamline workflows and ensure important updates are always within reach:",
    listItems: [
      "Sync your Slack teams and channels",
      "Receive important notifications and updates within your Slack workspace",
      "Stay updated with real-time issue notifications",
      "Gain insights with cross-platform reporting and analytics",
    ],
  },
  Jira: {
    description:
      "Integrate with Jira to streamline project management and effortlessly sync tasks, issues, and team collaboration. By connecting to Jira, you’ll unlock powerful features to manage your projects more efficiently",
    listItems: [
      "Sync Jira projects, boards, and issues with our platform",
      "Track and update tasks directly from within our system",
      "Automatically assign and manage team members from Jira",
      "Stay updated with real-time issue progress and notifications",
      "Gain insights with cross-platform reporting and analytics",
    ],
  },
  Github: {
    description:
      "Integrate with GitHub to seamlessly manage your code repositories, automate workflows, and collaborate with your development team. By connecting to GitHub, you can enhance your development process and gain better visibility into your project’s codebase:",
    listItems: [
      "Sync your Github repositories and projects",
      "Receive important notifications and updates within your Github workspace",
      "Set up automated notifications for code updates and pull request reviews",
      "Gain insights with cross-platform reporting and analytics",
    ],
  },
  Drive: {
    description:
      "Integrate your workspace with Google Drive to manage your files and documents. Once connected, you'll be able to:",
    listItems: [
      "Sync your Google Drive files and documents",
      "Receive important notifications and updates within your Google Drive workspace",
    ],
  },
  Meet: {
    description:
      "Integrate your workspace with Google Meet to manage your video calls and meetings. Once connected, you'll be able to:",
    listItems: [
      "Sync your Google Meet video calls and meetings",
      "Receive important notifications and updates within your Google Meet workspace",
      "Stay updated on document changes with version history and notifications",
      "Updates when shared files are edited or when someone leaves comments or suggestions.",
    ],
  },
  Teams: {
    description:
      "Integrate with Microsoft Teams to streamline communication, enhance collaboration, and manage your projects seamlessly across your workspace. By connecting Microsoft Teams, you’ll unlock key features to keep your team aligned and productive:",
    listItems: [
      "Sync your Microsoft Teams video calls and meetings",
      "Sync Teams channels and conversations with our platform",
      "Receive important notifications and updates within your Microsoft Teams workspace",
      "Sync Teams channels with project tasks, ensuring your team stays updated and aligned.",
      "Receive project notifications, task updates, and milestones directly in Teams channels, so your team never misses important information.",
    ],
  },
  Figma: {
    description:
      "Integrate with Figma to bring your design workflows and project management into one platform. By connecting Figma, you can easily collaborate on designs, manage feedback, and ensure version control, all while keeping your team aligned on the latest design progress:",
    listItems: [
      "Sync your Figma projects and tasks",
      "Receive important notifications and updates within your Figma workspace",
      "Manage design feedback and revisions directly from our platform",
      "Manage design feedback, revisions, and approvals in a single, integrated environment.",
      "Receive alerts when design changes are made or feedback is added, ensuring your team stays informed about the latest updates.",
    ],
  },
  Zoom: {
    description:
      "Integrate with Zoom to streamline your meetings, collaborate in real-time, and stay connected with your team. By connecting Zoom, you can schedule and manage video calls directly from our platform, ensuring smoother workflows and improved team coordination:",
    listItems: [
      "Sync Zoom meeting links with tasks, events, or milestones",
      "Receive important notifications and updates within your Zoom workspace",
      "Set up automatic reminders for upcoming Zoom meetings, so your team is always on time.",
      "Receive alerts when new Zoom meetings are scheduled or when meeting details change.",
    ],
  },
  Notion: {
    description:
      "Integrate with Notion to bring your knowledge base, project management, and team collaboration together in one place. By connecting Notion, you can easily organize and manage your documents, tasks, and notes, keeping your team aligned and informed:",
    listItems: [
      "Sync your Notion projects and tasks",
      "Receive important notifications and updates within your Notion workspace",
      "Sync Notion pages, databases, and tasks with our platform",
      "Stay updated on document changes and project updates with automatic notifications.",
    ],
  },
  Dropbox: {
    description:
      "Integrate with Dropbox to manage your files, documents, and projects seamlessly across your workspace. By connecting Dropbox, you can easily sync and share files, collaborate on documents, and keep your team aligned and productive:",
    listItems: [
      "Sync your Dropbox files and documents",
      "Receive important notifications and updates within your Dropbox workspace",
      "Set up automated notifications for file updates and changes",
      "Stay updated on document changes and project updates with automatic notifications.",
      "Automatically track file updates and changes with version control and notifications.",
    ],
  },
};

export const getGradeMapping = (percentage) => {
  if (percentage >= 80) {
    return "A";
  } else if (percentage >= 60) {
    return "B";
  } else {
    return "C";
  }
};
