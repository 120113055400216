import React from "react";
import {
  getGradeMapping,
  getSurveyGradeBackgroundColors,
  getSurveyGradeTextColors,
} from "../../../../utilities/Utils";
import dayjs from "dayjs";

const SurveyQuestions = ({ currentrow, handleSurveyResponseClick }) => {
  // const surveyResponsePercetage = currentrow.triggered_suveys.filter(
  //   (triggeredSuvey) => {
  //     return triggeredSuvey.survey_response !== null;
  //   },
  // );

  // const calculatedSurveyResponsePercentage = Math.round(
  //   (surveyResponsePercetage.length / currentrow.triggered_suveys.length) * 100,
  // );
  const surveyResponsePercetage = Math.round(
    (currentrow.survey_responses.length / currentrow.survey_assignees.length) *
      100,
  );
  return (
    <div>
      <div
        className="d-flex justify-content-between col-lg-12"
        style={{
          border: "1px solid #DEDEDE",
          borderRadius: "10px",
          padding: "12px 11px",
        }}
      >
        <div className="d-flex">
          <div
            className="d-flex flex-column align-items-center"
            style={{
              background: getSurveyGradeBackgroundColors(
                getGradeMapping(surveyResponsePercetage),
              ),
              marginRight: "26px",
              borderRadius: "10px",
              padding: "12px 21px",
              width: "92px",
            }}
          >
            <span
              className="d-flex head3-semi-bold mb-3"
              style={{
                color: getSurveyGradeTextColors(
                  getGradeMapping(surveyResponsePercetage),
                ),
              }}
            >
              {getGradeMapping(surveyResponsePercetage)}
            </span>
            <span
              className="d-flex head6-medium"
              style={{
                color: getSurveyGradeTextColors(
                  getGradeMapping(surveyResponsePercetage),
                ),
              }}
            >
              {surveyResponsePercetage || 0}
              {"%"}
            </span>
          </div>
          <div
            className="d-flex justify-content-between flex-column"
            style={{ padding: "14px 0" }}
          >
            <div className="d-flex head5-medium grey9 mb-3">
              {currentrow?.question}
            </div>
            <span
              className="d-flex primary-color caption1"
              style={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => handleSurveyResponseClick(currentrow)}
            >
              {"See Responses"}
            </span>
          </div>
        </div>

        <div
          className="d-flex justify-content-between flex-column"
          style={{ padding: "20px 0" }}
        >
          <div className="d-flex caption1 grey9">{`Asked By: ${currentrow.user_id}`}</div>
          <span className="d-flex caption1 grey9">{`Created at: ${dayjs(currentrow.start_date).format("DD/MM/YYYY")}`}</span>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default SurveyQuestions;
