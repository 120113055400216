/* eslint-disable indent */
import React, { useState } from "react";
// import { ReactComponent as CalendarBlankIcon } from "../../assets/icons/CalendarBlank.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/date-calendar.svg";
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import Button from "../sub-components/Button";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import PopperDropdown from "../DropDowns/PopperDropdown";

export const DateRangePicker = ({
  value,
  setStartDate,
  setEndDate,
  fetchDatesData,
}) => {
  const [selectedStartDate, setSelectedStartDate] = useState(
    dayjs().subtract(1, "week").format("YYYY-MM-DDTHH:mm:ss.SSS"),
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
  );

  const [isCustomDateOptionSelected, setIsCustomDateOptionSelected] =
    useState(false);

  const [customDateOption, setCustomDateOption] = useState("Previous week");

  const onChange = (e, name) => {
    const date = dayjs(e).format("YYYY-MM-DDTHH:mm:ss.SSS");

    if (name === "from") {
      setSelectedStartDate(date);
    }
    if (name === "to") {
      setSelectedEndDate(date);
    }
  };

  const handleApply = () => {
    setStartDate(selectedStartDate);
    setEndDate(selectedEndDate);
    // setIsDateMenuOpen(false);
    setIsCustomDateOptionSelected(false);
    fetchDatesData(selectedStartDate, selectedEndDate);
  };

  const handleShortcutApply = (selectedStartDate, selectedEndDate) => {
    // setStartDate(selectedStartDate);
    // setEndDate(selectedEndDate);
    setSelectedStartDate(selectedStartDate);
    setSelectedEndDate(selectedEndDate);
    fetchDatesData(selectedStartDate, selectedEndDate);
  };

  const [isDateShortcutOptionsOpen, setDateShortcutOptionsOpen] =
    React.useState(false);
  const dateShortcutOptionsAnchorRef = React.useRef(null);

  const handleToggleDateShortcutOptions = () => {
    setDateShortcutOptionsOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setDateShortcutOptionsOpen(false);
  };

  const handleCustomSelected = () => {
    setIsCustomDateOptionSelected(true);
    handleClose();
  };
  const handleCustomDateRangeClose = () => {
    setIsCustomDateOptionSelected(false);
    // handleClose();
  };

  const handleDateRangeSelected = async (e) => {
    let newDate = dayjs();
    switch (e.target.id.toLowerCase()) {
      case "custom":
        setCustomDateOption("custom");
        handleCustomSelected();
        break;

      case "today":
        setCustomDateOption("today");
        handleShortcutApply(
          dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
          dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
        );
        // fetchDatesData(
        //   dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
        //   dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
        // );
        break;

      case "yesterday":
        setCustomDateOption("yesterday");
        newDate = dayjs().subtract(1, "day");
        handleShortcutApply(
          newDate.format("YYYY-MM-DDTHH:mm:ss.SSS"),
          dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
        );
        // fetchDatesData(
        //   newDate.format("YYYY-MM-DDTHH:mm:ss.SSS"),
        //   dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
        // );
        break;

      case "previous week":
        setCustomDateOption("previous week");
        newDate = dayjs().subtract(1, "week");
        handleShortcutApply(
          newDate.format("YYYY-MM-DDTHH:mm:ss.SSS"),
          dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
        );
        // fetchDatesData(
        //   newDate.format("YYYY-MM-DDTHH:mm:ss.SSS"),
        //   dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
        // );
        break;
      case "last 6 months":
        setCustomDateOption("last 6 months");
        newDate = dayjs().subtract(6, "month");
        handleShortcutApply(
          newDate.format("YYYY-MM-DDTHH:mm:ss.SSS"),
          dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
        );
        // fetchDatesData(
        //   newDate.format("YYYY-MM-DDTHH:mm:ss.SSS"),
        //   dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
        // );
        break;

      case "last year":
        setCustomDateOption("last year");
        newDate = dayjs().subtract(1, "year");
        handleShortcutApply(
          newDate.format("YYYY-MM-DDTHH:mm:ss.SSS"),
          dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
        );
        // fetchDatesData(
        //   newDate.format("YYYY-MM-DDTHH:mm:ss.SSS"),
        //   dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS"),
        // );
        break;
    }

    handleClose();
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="d-flex align-items-center">
        {/* <div
          ref={dateShortcutOptionsAnchorRef}
          id="composition-button"
          aria-controls={
            isDateShortcutOptionsOpen ? "composition-menu" : undefined
          }
          aria-expanded={isDateShortcutOptionsOpen ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggleDateShortcutOptions}
        >
          <div className="d-flex" style={{}}>
            <span style={{ color: "#343A40" }}></span>
            <span className="s2 grey8">
              {dayjs(value.from).format("MMMM D, YYYY")}{" "}
            </span>

            <span className="s2 grey8 ml-2 mr-2" style={{ color: "#343A40" }}>
              {" "}
              -{" "}
            </span>

            <span className="s2 grey8">
              {dayjs(value.to).format("MMMM D, YYYY")}
            </span>

            <CalendarIcon
              style={{
                width: "20px",
                height: "20px",
                marginLeft: "12px",
              }}
            />
          </div>
        </div> */}

        {/*  */}
        <PopperDropdown
          anchorRef={dateShortcutOptionsAnchorRef}
          isDropdownOpen={isDateShortcutOptionsOpen}
          handleToggle={handleToggleDateShortcutOptions}
          handleClose={handleClose}
          // dropdownDisplayText={"adsdsadasd"}
          customRenderFieldChildren={
            <div
              className="d-flex align-items-center"
              style={{
                height: "40px",
                background: "#FFF",
                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)",
                border: "1px solid #E9ECEF",
                borderRadius: "10px",
                padding: "6px 12px",
                cursor: "pointer",
              }}
            >
              <span style={{ color: "#343A40" }}></span>
              <span className="s2 grey8">
                {dayjs(value.from).format("MMMM D, YYYY")}{" "}
              </span>

              <span className="s2 grey8 ml-2 mr-2" style={{ color: "#343A40" }}>
                {" "}
                -{" "}
              </span>

              <span className="s2 grey8">
                {dayjs(value.to).format("MMMM D, YYYY")}
              </span>

              <CalendarIcon
                style={{
                  width: "20px",
                  height: "20px",
                  marginLeft: "12px",
                }}
              />
            </div>
          }
          menuItems={[
            "Today",
            "Yesterday",
            "Previous week",
            "Last 6 months",
            "Last year",
            "Custom",
          ]}
          handleOptionSelected={handleDateRangeSelected}
          activeMenuItem={customDateOption}
        />
        {/* <Popper
          open={isDateShortcutOptionsOpen}
          anchorEl={dateShortcutOptionsAnchorRef.current}
          role={undefined}
          transition
          disablePortal
          sx={{ zIndex: "5", paddingTop: "6px" }}
          placement="bottom-end"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "top right",
              }}
            >
              <Paper
                sx={{
                  borderRadius: "6px",
                  border: "1px solid #BFBFBF",
                  background: "#FFF",
                  width: "180px",
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                  >
                    <MenuItem
                      onClick={handleDateRangeSelected}
                      sx={{
                        "&.Mui-selected": {
                          background: "#F4E6FF",
                          color: "#7B2CBF",
                        },
                      }}
                      selected
                      id="today"
                      className="body2-medium grey8"
                    >
                      Today
                    </MenuItem>
                    <MenuItem
                      className="body2-medium grey8"
                      id="yesterday"
                      onClick={handleDateRangeSelected}
                    >
                      Yesterday
                    </MenuItem>
                    <MenuItem
                      className="body2-medium grey8"
                      id="last week"
                      onClick={handleDateRangeSelected}
                    >
                      Previous week
                    </MenuItem>
                    <MenuItem
                      className="body2-medium grey8"
                      id="last 6 months"
                      onClick={handleDateRangeSelected}
                    >
                      Last 6 months
                    </MenuItem>
                    <MenuItem
                      className="body2-medium grey8"
                      id="last year"
                      onClick={handleDateRangeSelected}
                    >
                      Last year
                    </MenuItem>
                    <MenuItem
                      className="body2-medium grey8"
                      onClick={handleCustomSelected}
                    >
                      Custom
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper> */}

        {/*  */}
        <Popper
          open={isCustomDateOptionSelected}
          anchorEl={dateShortcutOptionsAnchorRef.current}
          role={undefined}
          transition
          disablePortal
          sx={{ zIndex: "5" }}
          placement="bottom-end"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "top right",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCustomDateRangeClose}>
                  <div onClick={(e) => e.stopPropagation()}>
                    <div className="d-flex">
                      <div className="d-flex flex-column">
                        <StaticDatePicker
                          slotProps={{
                            toolbar: { hidden: true },
                            actionBar: {
                              actions: [],
                            },
                          }}
                          defaultValue={dayjs(selectedStartDate)}
                          onChange={(e) => {
                            onChange(e, "from");
                          }}
                        />
                      </div>
                      <div className="d-flex flex-column">
                        <StaticDatePicker
                          slotProps={{
                            toolbar: { hidden: true },
                            actionBar: {
                              actions: [],
                            },
                          }}
                          defaultValue={dayjs(selectedEndDate)}
                          onChange={(e) => {
                            onChange(e, "to");
                          }}
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-end mr-4">
                      <Button
                        buttonHollowLg
                        title="Cancel"
                        className="s1 grey8"
                        style={{
                          width: "88px",
                          marginRight: "12px",
                          height: "41px",
                        }}
                        onClick={handleCustomDateRangeClose}
                      />

                      <Button
                        buttonFilledFull
                        className="b1 grey1"
                        title="Apply"
                        style={{ width: "77px", height: "41px" }}
                        onClick={() => handleApply()}
                      />
                    </div>
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </LocalizationProvider>
  );
};
