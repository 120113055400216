import dayjs from "dayjs";
// import utc from "dayjs/plugin/utc";
// import timezone from "dayjs/plugin/timezone";
import React from "react";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import BarChartIcon from "@mui/icons-material/BarChart";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
export const barGraphDateConversion = (date) => {
  const gsDayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const d = new Date(date);
  const dayName = gsDayNames[d.getDay()];
  return dayName;
};

export const diffInMilliseconds = (date2, date1) => Math.abs(date2 - date1);

export const diffInDays = (date2, date1) =>
  Math.floor(diffInMilliseconds(date2, date1) / (1000 * 60 * 60 * 24));

export const getBarGraphValues = (
  value,
  selectedGranularity,
  indexBy,
  matchingUsersInGraphData,
) => {
  if (indexBy === "DumpTableMain.userId") {
    if (matchingUsersInGraphData.length > 0) {
      const group = matchingUsersInGraphData.find((users) => {
        return parseInt(users.user_id) === parseInt(value);
      });
      return group?.name;
    }
  }

  // if (selectedGranularity === "hourly") {
  //   // const date = new Date(value);

  //   // // Get the user's local timezone offset in minutes
  //   // const userTimezoneOffset = date.getTimezoneOffset();

  //   // // Apply the user's local timezone offset to the date
  //   // date.setMinutes(date.getMinutes() - userTimezoneOffset);

  //   // // Convert the date to the user's local timezone
  //   // const userLocalDate = date.toLocaleString(undefined, {
  //   //   timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  //   // });
  //   const formattedDate = dayjs(value).format("DD-MM-YYYY h:mm A");

  //   // return dayjs(userLocalDate).format("DD-MM-YYYY h:mm A");
  //   return formattedDate;
  // }
  // return dayjs(value).format("DD-MM-YYYY");
  console.log(
    "selectedGranularityselectedGranularityselectedGranularity",
    selectedGranularity,
  );
  if (selectedGranularity === "second") {
    return dayjs(value).format(":ss");
  }
  if (selectedGranularity === "minute") {
    return dayjs(value).format("HH:mm");
  }
  if (selectedGranularity === "hour") {
    return dayjs(value).format("h A");
  }
  if (selectedGranularity === "day") {
    return dayjs(value).format("ddd DD");
  }
  if (selectedGranularity === "week") {
    return dayjs(value).format("MMM DD");
  }
  if (selectedGranularity === "month") {
    return dayjs(value).format("MMMM");
  }
  if (selectedGranularity === "year") {
    return dayjs(value).format("YYYY");
  }
};

export const getEmojisMapping = (value) => {
  const emojis = [
    { reaction: "😍", text: "Amazing" },
    { reaction: "😀", text: "Good" },
    { reaction: "🤔", text: "Average" },
    { reaction: "😕", text: "Poor" },
    { reaction: "😡", text: "Worst" },
  ];

  const emojiObject = emojis.find((emoji) => emoji.text === value);
  return emojiObject ? emojiObject.reaction : null;
  // emojis["text"]
};

export const LineGraphEmptyStateIcon = (marginTop, text) => {
  return (
    <div style={{ marginTop: marginTop }}>
      <div>
        {" "}
        <div className="d-flex justify-content-center">
          <StackedLineChartIcon />
        </div>{" "}
        <div
          className={`d-flex justify-content-center body2-medium grey7 mt-2`}
        >
          Activity not found
        </div>
      </div>
    </div>
  );
};
export const BarGraphEmptyStateIcon = (marginTop, text) => {
  return (
    <div style={{ marginTop: marginTop }}>
      <div>
        {" "}
        <div className="d-flex justify-content-center">
          <BarChartIcon />
        </div>{" "}
        <div
          className={`d-flex justify-content-center body2-medium grey7 mt-2`}
        >
          Activity not found
        </div>
      </div>
    </div>
  );
};
export const PieGraphEmptyStateIcon = (marginTop, text) => {
  return (
    <div style={{ marginTop: marginTop }}>
      <div>
        {" "}
        <div className="d-flex justify-content-center">
          <DonutLargeIcon />
        </div>{" "}
        <div
          className={`d-flex justify-content-center body2-medium grey7 mt-2`}
        >
          Activity not found
        </div>
      </div>
    </div>
  );
};
