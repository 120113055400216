import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { secure_instance } from "../../axios/axios-config";

const initialState = {
  mySurveysData: [],
  mySurveyCurrentlyOpened: [],
  sideMenu: {
    isSideMenuOpen: false,
    tableData: {
      sideMenuTableLoading: false,
      usersData: [],
      rowsData: [],
      rowsPerPage: 5,
      page: 1,
      selected: [],
    },
  },
};

export const getSurveysDrawerTableData = createAsyncThunk(
  "surveys/getSurveysDrawerTableData",
  async (type, { rejectWithValue, dispatch }) => {
    try {
      dispatch(toggleTableLoading());
      const response = await secure_instance.request({
        method: "GET",
        url: `v1/${type}`,
      });
      dispatch(toggleTableLoading());
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createNewSurvey = createAsyncThunk(
  "surveys/createNewSurvey",
  async (data, { rejectWithValue }) => {
    try {
      const response = await secure_instance.request({
        method: "POST",
        url: "v1/survey/",
        data,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const listAllSurveys = createAsyncThunk(
  "surveys/listAllSurveys",
  async (_, { rejectWithValue }) => {
    try {
      const response = await secure_instance.request({
        method: "GET",
        url: "v1/survey/",
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const listSingleSurvey = createAsyncThunk(
  "surveys/listSingleSurvey",
  async (id, { rejectWithValue }) => {
    try {
      const response = await secure_instance.request({
        method: "GET",
        url: `v1/survey/${id}`,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

const surveySlice = createSlice({
  name: "surveys",
  initialState,
  reducers: {
    toggleSideMenu(state) {
      state.sideMenu.isSideMenuOpen = !state.sideMenu.isSideMenuOpen;
    },
    setSideMenuTablePage: (state, action) => {
      state.sideMenu.tableData.page = action.payload;
    },
    emptySingleSurvey: (state, action) => {
      state.mySurveyCurrentlyOpened = [];
    },
    toggleTableLoading(state) {
      state.sideMenu.tableData.sideMenuTableLoading = !state.sideMenu.tableData.sideMenuTableLoading;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(listAllSurveys.pending, (state) => {
        state.loading = true;
      })
      .addCase(listAllSurveys.fulfilled, (state, action) => {
        state.mySurveysData = action.payload;
        state.loading = false;
      })
      .addCase(listAllSurveys.rejected, (state) => {
        state.loading = false;
      })
      .addCase(listSingleSurvey.pending, (state) => {
        state.loading = true;
      })
      .addCase(listSingleSurvey.fulfilled, (state, action) => {
        state.mySurveyCurrentlyOpened = action.payload;
        state.loading = false;
      })
      .addCase(listSingleSurvey.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { toggleSideMenu, setSideMenuTablePage, toggleTableLoading, emptySingleSurvey } = surveySlice.actions;

export default surveySlice.reducer;
