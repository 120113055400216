import React, { useState, useEffect } from "react";
import { Drawer } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setSideMenuTablePage } from "../../../../redux/slices/departmentSlice";
import TeamMembers from "./TeamMembers";
import Tabv2 from "../../../../components/sub-components/Tabv2";
import ActionButtons from "./ActionButtons";
import { ReactComponent as Arrow_left } from "../../../../assets/icons/ArrowLeft.svg";
import { ReactComponent as Close } from "../../../../assets/icons/X.svg";
import SurveyForm from "./SurveyForm";
import dayjs from "dayjs";
import {
  createNewSurvey,
  listAllSurveys,
} from "../../../../redux/slices/surveySlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { TEAMS_COLUMNS } from "../../../../utilities/Constants";

const usersColumns = [
  {
    key: "full_name",
    name: "Name",
    // minWidth: 280,
    // padding: "0 0 0 16px",
    accessor: (row) => `${row?.first_name ?? ""}  ${row?.last_name ?? ""}`,
  },
  {
    key: "primary_email",
    name: "Email",
    // minWidth: 280,
    accessor: (row) =>
      row.identifiers?.map(
        (identifier) => identifier.is_primary && identifier.email,
      ),
  },
  {
    key: "title",
    name: "Job Title",
    // minWidth: 280,
  },
  {
    key: "role",
    name: "Privilege(s)",
    // minWidth: 280,
  },
];

const departmentColumns = [
  {
    key: "name",
    name: "Full Name",
    accessor: (row) => `${row.name ?? ""}`,
  },
  {
    key: "okr",
    name: "OKR",
    accessor: (row) => `${row.teams?.length ?? ""}`,
  },
  {
    key: "lead",
    name: "Lead(s)",
    accessor: (row) =>
      row.first_name ? `${row.first_name} ${row.last_name}` : "",
  },
];

export default function CreateNewSurveyMenu(props) {
  const { setCurrentRowToEdit, isSideMenuOpen } = props;

  const { loading } = useSelector((state) => state.departmentsReducer);
  const user = useSelector((state) => state.authReducer.user);
  const [addedTeams, setAddedTeams] = useState([]); // teams which have been added
  const [selectedTab, setSelectedTab] = useState({ id: 1 });
  const [isRowClicked, setIsRowClicked] = useState(false);
  const tabsInitialState = [
    { id: 1, title: "Survey Details" },
    { id: 2, title: "Assignee(s)" },
  ];
  const [isConfirmationModal, setIsConfirmationModal] = useState(null);
  const [surveyQuestion, setSurveyQuestion] = useState("");
  const [selectedSurveyType, setSelectedSurveyType] = useState(null);
  const [selectedSubDepartments, setSelectedSubDepartments] = useState([]);
  const [selectedRecurrence, setSelectedRecurrence] = useState([]);
  const [departmentEmail, setDepartmentEmail] = useState("");
  const [objectiveType, setObjectiveType] = useState(null);
  const [addPeopleToObjectiveSelected, setAddPeopleToObjectiveSelected] =
    useState([]);
  const [selectedItem, setSelectedItem] = useState("text");
  const [selectedDate, setSelectedDate] = useState({
    start_date: null,
    end_date: null,
  });
  const [addedUsersSelected, setAddedUsersSelected] = useState([]);

  const dispatch = useDispatch();

  const refreshStates = () => {
    setSurveyQuestion("");
    setSelectedSurveyType({});
    setDepartmentEmail("");
    setCurrentRowToEdit({});
    setIsRowClicked(false);
    setIsConfirmationModal(false);
    setAddedTeams([]);
    setSelectedSubDepartments([]);
    setSelectedTab({ id: 1 });
    setSelectedDate({
      start_date: null,
      end_date: null,
    });
    setSelectedRecurrence([]);
  };

  const handleSideMenuClose = () => {
    refreshStates();
    props.handleSideMenu();
    // dispatch(toggleDepartmentsSideMenu());
  };

  const handleCreateNewDepartement = async () => {
    const surveyData = {
      question: surveyQuestion,
      category: selectedSurveyType.toLowerCase(),
      rucurrence: selectedRecurrence.toLowerCase(),
      start_date: dayjs(selectedDate.start_date).format("YYYY-MM-DD"),
      end_date: dayjs(selectedDate.end_date).format("YYYY-MM-DD"),
      response_type: selectedItem,
      user_id: user.userId,
      tenant_id: user.tenantId,
      // survey_assignees: [{ user_id: 1, tenant_id: 1 }],
      survey_assignees: addedTeams.map((user) => {
        return {
          user_id: user.user_id,
          tenant_id: user.tenant_id,
        };
      }),
    };

    try {
      const actionResult = await dispatch(createNewSurvey(surveyData));
      const result = unwrapResult(actionResult);
      if (result) {
        handleSideMenuClose();
        dispatch(listAllSurveys());
      }
    } catch (error) {
      console.error("Failed to create survey:", error);
    }
  };

  const tableColumnsCheck = (type, tableData) => {
    if (type && tableData && tableData.length) {
      if (type == "departments/") {
        return departmentColumns;
      } else if (type == "teams/") {
        return TEAMS_COLUMNS;
      } else if (type == "user_profile/?type=active") {
        return usersColumns;
      }
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (!isSideMenuOpen) {
      refreshStates();
    }
  }, [isSideMenuOpen]);

  return (
    <Drawer
      anchor="right"
      open={isSideMenuOpen}
      onClose={handleSideMenuClose}
      classes={{ paper: "main-drawer" }}
    >
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-between">
            <span className="head4 grey8" style={{ fontWeight: "400" }}>
              {isConfirmationModal ? (
                <Arrow_left
                  stroke="#343A40"
                  style={{
                    margin: "2px 20px 5px 0",
                    width: "24px",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsConfirmationModal(false)}
                />
              ) : (
                <Close
                  stroke="#343A40"
                  strokeWidth="2"
                  style={{
                    margin: "0px 20px 3px -16px",
                    width: "24px",
                    cursor: "pointer",
                  }}
                  onClick={handleSideMenuClose}
                />
              )}
              {isRowClicked ? props.currentRowToEdit.name : "Create new Survey"}
            </span>
            <div
              className="d-flex align-items-center flex-row-reverse"
              style={{ width: "fit-content" }}
            >
              <ActionButtons
                surveyQuestion={surveyQuestion}
                selectedSurveyType={selectedSurveyType}
                addedTeams={addedTeams}
                handleSideMenuClose={handleSideMenuClose}
                loading={loading}
                isRowClicked={isRowClicked}
                deleteDepartment={props.deleteDepartment}
                isConfirmationModal={isConfirmationModal}
                setIsConfirmationModal={setIsConfirmationModal}
                handleCreateNewDepartement={handleCreateNewDepartement}
                setSelectedTab={setSelectedTab}
              />
            </div>
          </div>
          {/* isEditableState || isRowClicked) &&  */}
          {!isConfirmationModal && (
            <div>
              <Tabv2
                tabs={tabsInitialState}
                selectedTab={selectedTab}
                onTabChange={(index) => {
                  setSelectedTab(tabsInitialState[index]);
                  dispatch(setSideMenuTablePage(1));
                }}
              />
            </div>
          )}
          <SurveyForm
            {...props}
            handleSideMenuClose={handleSideMenuClose}
            selectedTab={selectedTab}
            surveyQuestion={surveyQuestion}
            setSurveyQuestion={setSurveyQuestion}
            selectedSurveyType={selectedSurveyType}
            setSelectedSurveyType={setSelectedSurveyType}
            selectedRecurrence={selectedRecurrence}
            setSelectedRecurrence={setSelectedRecurrence}
            selectedSubDepartments={selectedSubDepartments}
            setSelectedSubDepartments={setSelectedSubDepartments}
            departmentEmail={departmentEmail}
            setDepartmentEmail={setDepartmentEmail}
            isConfirmationModal={isConfirmationModal}
            isRowClicked={isRowClicked}
            currentRowToEdit={props.currentRowToEdit}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
          <TeamMembers
            addedTeams={addedTeams}
            setAddedTeams={setAddedTeams}
            selectedTab={selectedTab}
            isConfirmationModal={isConfirmationModal}
            currentRowToEdit={props.currentRowToEdit}
            isRowClicked={isRowClicked}
            setIsRowClicked={setIsRowClicked}
            tableColumnsCheck={tableColumnsCheck}
            objectiveType={objectiveType}
            setObjectiveType={setObjectiveType}
            addPeopleToObjectiveSelected={addPeopleToObjectiveSelected}
            setAddPeopleToObjectiveSelected={setAddPeopleToObjectiveSelected}
            addedUsersSelected={addedUsersSelected}
            setAddedUsersSelected={setAddedUsersSelected}
          />
        </div>
      </div>
    </Drawer>
  );
}
