import React, { useState } from "react";
import { Table } from "../../../../components/Tables/Table";
import { useDispatch, useSelector } from "react-redux";
import { setSideMenuTablePage } from "../../../../redux/slices/surveySlice";
import dayjs from "dayjs";

function filterTriggerSurvey(row) {
  const filteredSurvey = row.triggered_suveys.find(
    (item) => item.survey_response !== null,
  );
  return filteredSurvey;
}

const columns = [
  {
    key: "full_name",
    name: "Full Name",
    accessor: (row) => {
      const filteredSurvey = filterTriggerSurvey(row);
      return filteredSurvey
        ? `${filteredSurvey.user_profiles.first_name} ${filteredSurvey.user_profiles.last_name}`
        : "No Response";
    },
  },
  {
    key: "response",
    name: "Response",
    accessor: (row) => {
      const filteredSurvey = filterTriggerSurvey(row);
      return filteredSurvey
        ? `${filteredSurvey.survey_response.response}`
        : "No Response";
    },
    minWidth: 210,
  },
  {
    key: "response_submitted",
    name: "Response Submitted",
    align: "center",
    accessor: (row) => {
      const filteredSurvey = filterTriggerSurvey(row);
      return filteredSurvey
        ? `${dayjs(filteredSurvey.created_at).format("DD/MM/YYYY")}`
        : "No Response";
    },
  },
];

const SurveyResponse = ({ surveyResponseData, searchInputValue }) => {
  const dispatch = useDispatch();
  const { page } = useSelector(
    (state) => state.surveysReducer.sideMenu.tableData,
  );

  const [selected, setSelected] = useState(false);

  const setTablePage = (page) => {
    dispatch(setSideMenuTablePage(page));
  };

  return (
    <div>
      <div
        style={{
          border: "3px solid #F5F5F5",
          boxShadow: "0px 4px 4px 0px #FAFAFA",
          borderRadius: "10px",
          padding: "20px 34px",
        }}
      >
        <Table
          data={surveyResponseData}
          columns={columns}
          loading={false}
          page={page}
          inputValue={searchInputValue}
          filters={""}
          setTablePage={setTablePage}
          selected={selected}
          setSelected={setSelected}
          // handleToolbarAction={handleToolbarAction}
        />
      </div>
    </div>
  );
};

export default SurveyResponse;
